import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { QuoteRequest } from '../../models';
import { QuoteRequestsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class QuoteRequestsStoreEffects {
  constructor(
    private dataService: QuoteRequestsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  index$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertiesIds, params }) =>
        this.dataService.index(propertiesIds, params).pipe(
          map(({ data, meta }: IResponseSuccess<QuoteRequest[]>) =>
            fromActions.loadSuccess({
              quoteRequests: data,
              pagination: meta.pagination,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  show$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ quoteRequestId }) =>
        this.dataService.show(quoteRequestId).pipe(
          map(({ data: [quoteRequest] }: IResponseSuccess<QuoteRequest[]>) =>
            fromActions.loadDetailsSuccess({
              quoteRequest,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  setArchived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setArchivedRequest),
      switchMap(({ quoteRequestId, archive, onSuccess, onFailure }) =>
        this.dataService.setArchived(quoteRequestId, archive).pipe(
          map(() => {
            if (onSuccess) {
              onSuccess();
            }

            this.notifications.push({
              title: upperFirst(this.translate.instant('done')),
              content: upperFirst(
                this.translate.instant('notifications.update_success', {
                  param: this.translate.instant('quote_requests'),
                }),
              ),
              type: 'success',
            });

            return fromActions.setArchivedSuccess({ quoteRequestId });
          }),
          catchError((error) => {
            if (onFailure) {
              onFailure();
            }

            this.errorHandler.handle(error);
            return of(fromActions.setArchivedFailure(error));
          }),
        ),
      ),
    ),
  );

  expand$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setExpand),
      filter(({ quoteRequest }) => !quoteRequest.detailed),
      map(({ quoteRequest }) =>
        fromActions.loadDetailsRequest({ quoteRequestId: quoteRequest.id }),
      ),
    ),
  );
}
