import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { quoteRequests, pagination }) =>
    fromState.featureAdapter.setAll(quoteRequests, {
      ...state,
      pagination,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.setArchivedRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(fromActions.setArchivedSuccess, (state, { quoteRequestId }) =>
    fromState.featureAdapter.removeOne(quoteRequestId, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.setArchivedFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadDetailsSuccess, (state, { quoteRequest }) =>
    fromState.featureAdapter.upsertOne(
      { ...quoteRequest, detailed: true },
      state,
    ),
  ),

  on(fromActions.setExpand, (state, { quoteRequest }) =>
    fromState.featureAdapter.updateOne(
      {
        id: quoteRequest.id,
        changes: { ...quoteRequest, expanded: !quoteRequest.expanded },
      },
      state,
    ),
  ),

  on(fromActions.resetState, () => fromState.initialState),
);

export function quoteRequestsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
