import { createAction, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { QuoteRequest, QuoteRequestStateEntity } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Quote Requests] Load Request',
  props<{ propertiesIds: number[]; params?: SearchParams }>(),
);
export const loadSuccess = createAction(
  '[Quote Requests] Load Success',
  props<{ quoteRequests: QuoteRequest[]; pagination: IPagination }>(),
);
export const loadFailure = createAction(
  '[Quote Requests] Load Failure',
  props<{ error: any }>(),
);

export const loadDetailsRequest = createAction(
  '[Quote Requests] Load Details Request',
  props<{ quoteRequestId: number }>(),
);
export const loadDetailsSuccess = createAction(
  '[Quote Requests] Load Details Success',
  props<{ quoteRequest: QuoteRequest }>(),
);
export const loadDetailsFailure = createAction(
  '[Quote Requests] Load Details Failure',
  props<{ error: any }>(),
);

export const setExpand = createAction(
  '[Quote Requests] Set Expand',
  props<{ quoteRequest: QuoteRequestStateEntity }>(),
);

export const setArchivedRequest = createAction(
  '[Quote Requests] Set Archived Request',
  props<{
    quoteRequestId: number;
    archive: boolean;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);
export const setArchivedSuccess = createAction(
  '[Quote Requests] Set Archived Success',
  props<{
    quoteRequestId: number;
  }>(),
);
export const setArchivedFailure = createAction(
  '[Quote Requests] Set Archived Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Quote Requests] Reset State');
