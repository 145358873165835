import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureAdapter, State } from './state';

export const selectState = createFeatureSelector<State>('quoteRequests');

export const selectAll = featureAdapter.getSelectors(selectState).selectAll;

export const selectOne = (quoteRequestId: number) =>
  createSelector(selectAll, (quoteRequests) =>
    quoteRequests?.find(({ id }) => id === quoteRequestId),
  );

export const selectPagination = createSelector(
  selectState,
  (state) => state.pagination,
);

export const selectError = createSelector(selectState, (state) => state.error);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);
